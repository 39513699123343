import { MoreVertical } from "lucide-react";
import "./openorders.scss";
import { useOtctrades } from "../../../providers/OtctradesProvider";
const Openorders = () => {
	const { otctrades, isLoadingOtctrades } = useOtctrades();

	return (
		<div className="dashboard_openorders_component">
			{!isLoadingOtctrades && (
				<table className="open_orders_table">
					<thead>
						<tr>
							<td>Pair</td>
							<td>Quantity</td>
							<td>Actual price</td>
							<td>Date</td>
							<td>Completion</td>
							<td>Details</td>
						</tr>
					</thead>
					<tbody>
						{otctrades.map((otctrade) => (
							<tr key={otctrade.id}>
								<td>
									{otctrade.sellCurrency}-{otctrade.buyCurrency}
								</td>
								<td>0.005 BTC</td>
								<td>19,727.16 USD</td>
								<td>21.10.2023 / 16:32 PM</td>
								<td>
									<progress id="file" value="32" max="100">
										32%
									</progress>
								</td>
								<td>
									<MoreVertical />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default Openorders;
