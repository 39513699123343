import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTransact, useTransactDispatch } from "../../../providers/TransactProvider";
import "./confirmotctrade.scss";
import { useAuth } from "../../../../../providers/AuthProvider";
import api from "../../../../../api";
import { Loader2 } from "lucide-react";
import { useCreateRequest } from "../../../../transactionDetails/createRequest/providers/createRequestProvider";
import { useMarketplace } from "../../../../marketplace/providers/MarketplaceProvider";
import { useNavigate } from "react-router-dom";
import { PAYMENT_METHOD_TYPES } from "../../../paymentmethods/configurepaymentmethods/configurepaymentmethods";
import { OTC_TRADE_VOLUME_TYPES } from "../otctrade";
import Big from "big.js";
import { formatAmount } from "../../../../../common/formatAmount";
import moment from "moment";
import _ from "lodash";

const Confirmotctrade = () => {
	const { authTokens } = useAuth();
	const { otcTradeState } = useTransact();
	const { request } = useCreateRequest();

	const transactDispatch = useTransactDispatch();
	const queryClient = useQueryClient();
	const { reloadMarketPlaceOrders } = useMarketplace();
	const navigate = useNavigate();

	const placeOtcTrade = useMutation({
		mutationFn: ({ order, token }) => api.placeOtcTrade({ order, token }),
		onError: () => {},
		onSuccess: async (data, variables, context) => {
			transactDispatch({ type: "tradeConfirmed" });
			await reloadMarketPlaceOrders();
			const orderId = data.data.data.attributes.id;
			navigate(`/transactiondetails/${orderId}`);
		},
	});

	const goToConfigurePaymentMethods = () => {
		transactDispatch({
			type: "goToConfigurePaymentMethods",
		});
	};

	const renderPaymentMethod = (paymentMethod) => (
		<div className="payment_method" key={paymentMethod.id}>
			{paymentMethod.type == PAYMENT_METHOD_TYPES.BANK_ACCOUNT && <div className="name">{paymentMethod.bankName}</div>}
			{paymentMethod.type == PAYMENT_METHOD_TYPES.PHONE_NUMBER && <div className="name">{paymentMethod.phonenumberLabel}</div>}
			{paymentMethod.type == PAYMENT_METHOD_TYPES.WALLET_ADDRESS && <div className="name">{paymentMethod.walletAddressLabel}</div>}
			<div className="details">
				{paymentMethod.type == PAYMENT_METHOD_TYPES.BANK_ACCOUNT && <div className="name">{paymentMethod.accountNumber}</div>}
				{paymentMethod.type == PAYMENT_METHOD_TYPES.PHONE_NUMBER && <div className="name">{paymentMethod.phonenumber}</div>}
				{paymentMethod.type == PAYMENT_METHOD_TYPES.WALLET_ADDRESS && <div className="name">{paymentMethod.walletAddress}</div>}
			</div>
		</div>
	);

	const renderDepositAmount = (deposit) => (
		<div className="payment_method" key={deposit.paymentMethod.id}>
			{deposit.paymentMethod.value.type == PAYMENT_METHOD_TYPES.BANK_ACCOUNT && <div className="name">{deposit.paymentMethod.label}</div>}
			{deposit.paymentMethod.value.type == PAYMENT_METHOD_TYPES.PHONE_NUMBER && <div className="name">{deposit.paymentMethod.label}</div>}
			{deposit.paymentMethod.value.type == PAYMENT_METHOD_TYPES.WALLET_ADDRESS && <div className="name">{deposit.paymentMethod.label}</div>}
			<div className="details">
				{deposit.instruction}
				{deposit.paymentMethod.value.currency}
			</div>
		</div>
	);

	const renderPaymentMethods = () => {
		if (!request.depositAmounts || request.depositAmounts.length === 0) {
			return request.paymentMethods.map(renderPaymentMethod);
		} else {
			return request.depositAmounts.map(renderDepositAmount);
		}
	};

	const createOrder = () => {
		const buyCurrency = otcTradeState.buy.currency;
		const buyAmount = otcTradeState.buy.amount;
		const sellCurrency = otcTradeState.sell.currency;
		const sellAmount = otcTradeState.sell.amount;

		const volumeMin = otcTradeState.volume.min;

		let volumeType = OTC_TRADE_VOLUME_TYPES.SELL_RATE;
		let volumeTypeAmount;

		switch (otcTradeState.volume.type) {
			case OTC_TRADE_VOLUME_TYPES.SELL_RATE:
				volumeTypeAmount = otcTradeState.volume.typeAmount;
				break;
			case OTC_TRADE_VOLUME_TYPES.BUY_RATE:
				volumeTypeAmount = Big(1).div(Big(otcTradeState.volume.typeAmount)).toString();
				break;
		}

		const windowPaymentType = otcTradeState.window.payment.type;
		const windowPaymentAmount = otcTradeState.window.payment.amount;
		const windowOrderType = otcTradeState.window.order.type;
		const windowOrderAmount = otcTradeState.window.order.amount;

		const paymentMethods = request.paymentMethods;
		const depositAmounts = request.depositAmounts || [];

		const expiry = moment().add(otcTradeState.window.order.amount, otcTradeState.window.order.type).toISOString();

		const order = {
			buyCurrency,
			buyAmount,
			sellCurrency,
			sellAmount,
			rawVolumeInput: {
				volumeType: otcTradeState.volume.type,
				volumeTypeAmount: otcTradeState.volume.typeAmount,
			},
			volumeType,
			volumeTypeAmount,
			volumeMin,
			windowPaymentType,
			windowPaymentAmount,
			windowOrderType,
			windowOrderAmount,
			paymentMethods,
			depositAmounts,
			expiry,
		};

		return order;
	};

	const getSellPrice = () => {
		const order = createOrder();
		const sellPrice = `1 ${order.sellCurrency} = ${formatAmount(order.volumeTypeAmount)} ${order.buyCurrency}`;
		return sellPrice;
	};

	const getBuyPrice = () => {
		const order = createOrder();
		const buyPrice = `1 ${order.buyCurrency} = ${formatAmount(Big(1).div(Big(order.volumeTypeAmount)).toString())} ${order.sellCurrency}`;
		return buyPrice;
	};

	const getExpiry = () => {
		const order = createOrder();
		const expiry = moment(order.expiry).format("MMM D, YYYY [at] h:mm A");
		return expiry;
	};

	const getPaymentMethodName = (paymentMethod) => {
		let name = "...";
		switch (paymentMethod.type) {
			case PAYMENT_METHOD_TYPES.BANK_ACCOUNT:
				name = `${paymentMethod.bankName} : ${paymentMethod.accountNumber}`;
				break;
			case PAYMENT_METHOD_TYPES.PHONE_NUMBER:
				name = `${paymentMethod.phonenumberLabel} : ${paymentMethod.phonenumber}`;
				break;
			case PAYMENT_METHOD_TYPES.WALLET_ADDRESS:
				name = `${paymentMethod.walletAddressLabel} : ${paymentMethod.walletAddress}`;
				break;
		}
		return name;
	};

	return (
		<div id="confirmotctrade_component">
			<div className="confirmotctrade_controls">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="confirmotctrade_controls_back"
					onClick={goToConfigurePaymentMethods}
					width="20"
					height="20"
					viewBox="0 0 24 24"
					fill="none">
					<path d="M16 4L8 12L16 20" stroke="#172C50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</div>
			<div className="confirmotctrade_header">
				<p>PLACE NEW ORDER</p>
			</div>
			<div className="confirmotctrade_summary">
				<div className="confirm_summary">
					<div className="confirm_header">Confirmation Page</div>

					<div className="confirm_info_v2 transaction_details">
						<div className="header">Transaction Details</div>
						<div className="section selling">
							<div className="lhs">Selling</div>
							<div className="rhs">
								{formatAmount(otcTradeState.sell.amount)} {otcTradeState.sell.currency}
							</div>
						</div>
						<div className="section buying">
							<div className="lhs">Buying</div>
							<div className="rhs">
								{formatAmount(otcTradeState.buy.amount)} {otcTradeState.buy.currency}
							</div>
						</div>
						<div className="section exchange_rate">
							<div className="lhs">Exchange rate</div>
							<div className="rhs">
								<div className="sell_price">{getSellPrice()}</div>
								<div className="buy_price">{getBuyPrice()}</div>
							</div>
						</div>

						{otcTradeState.volume.min && (
							<div className="section min_order_volume">
								<div className="lhs">Min. Order Volume</div>
								<div className="rhs">
									{formatAmount(otcTradeState.volume.min)} {otcTradeState.sell.currency}
								</div>
							</div>
						)}

						<hr />
					</div>

					<div className="confirm_info_v2 transaction_terms">
						<div className="header">Transaction terms</div>
						<div className="section expiry">
							<div className="lhs">Expiry Date</div>
							<div className="rhs">{getExpiry()}</div>
						</div>
						<div className="section order_window">
							<div className="lhs">Order window</div>
							<div className="rhs">
								{otcTradeState.window.order.amount} {otcTradeState.window.order.type}
							</div>
						</div>
						<div className="section payment_window">
							<div className="lhs">Payment window</div>
							<div className="rhs">
								{otcTradeState.window.payment.amount} {otcTradeState.window.payment.type.toLowerCase()}
							</div>
						</div>
						<hr />
					</div>

					<div className="confirm_info_v2 payment_methods">
						<div className="header">Payment method</div>

						{!_.isEmpty(request.depositAmounts) && (
							<>
								{request.depositAmounts.map((deposit) => (
									<div className="section payment_method" key={deposit.paymentMethod.id}>
										<div className="lhs">{getPaymentMethodName(deposit.paymentMethod.value)}</div>
										<div className="rhs">{deposit.instruction}</div>
									</div>
								))}
							</>
						)}

						{_.isEmpty(request.depositAmounts) && (
							<>
								{request.paymentMethods.map((paymentMethod) => (
									<div className="section payment_method" key={paymentMethod.id}>
										<div className="lhs">{getPaymentMethodName(paymentMethod)}</div>
										<div className="rhs"></div>
									</div>
								))}
							</>
						)}

						<hr />
					</div>
          
				</div>
				<div className="summary">
					{!placeOtcTrade.isPending && (
						<div
							onClick={() => {
								const order = createOrder();
								placeOtcTrade.mutate({
									order,
									token: authTokens.IdToken,
								});
							}}
							className="place_otctrade">
							Submit
						</div>
					)}

					{placeOtcTrade.isPending && (
						<div className="place_otctrade">
							<Loader2 className="place_otctrade_loader" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default Confirmotctrade;
