import { useEffect, useRef, useState } from "react";
import "./orderPops.scss";
import { Loader2 } from "lucide-react";
import _ from "lodash";
import api from "../../../api";
import { useAuth } from "../../../providers/AuthProvider";
import { useProfile } from "../../../providers/ProfileProvider";
import { useTransactionDetails } from "../providers/transactionDetailsProvider";
import { useForm } from "react-hook-form";

const PAYMENT_METHOD_TYPES = {
  BANK_ACCOUNT: "BANK_ACCOUNT",
  PHONE_NUMBER: "PHONE_NUMBER",
  WALLET_ADDRESS: "WALLET_ADDRESS",
};

const OrderPops = () => {
  const { authTokens } = useAuth();
  const {
    setValue,

    formState: { errors },
  } = useForm();

  const [popState, setPopState] = useState({
    paymentMethods: [
      {
        label: "",
        options: [],
      },
    ],
    selectedPaymentMethods: [],
    fileToUpload: {},
    uploadingPop: false,
    uploadProgress: 0,
    uploadStarted: false,
    uploadSuccessful: false,
    loadingPops: false,
    myPops: [],
    otherPops: [],
    updatingPops: [],
    rejectingPops: [],
  });

  const [fileToUpload, setFileToUpload] = useState({});
  const { profile } = useProfile();
  const { request, order } = useTransactionDetails();


  const userIsOwnerOfOrderRequest = () => {
    const isOwner = request.createdBy == profile.id;
    return isOwner;
  };

  const userIsBuyer = userIsOwnerOfOrderRequest;

  const userIsOwnerOfOrder = () => {
    const isOwner = order.createdBy == profile.id;
    return isOwner;
  };
  const userIsSeller = userIsOwnerOfOrder;

  useEffect(() => {
    //Build payment methods
    const buildBuyersPaymentMethods = userIsSeller();

    let paymentMethods;
    if (buildBuyersPaymentMethods) {
      // Building buyer's payment methods (from request)
      paymentMethods = request.paymentMethods || order.paymentMethods; //adding this for now to test, the request obj seems to be empty
    } else {
      // Building seller's payment methods (from order)

      paymentMethods = order.paymentMethods || request.order.paymentMethods;
    }

    const PaymentMethodsOptions = {};
    paymentMethods.forEach((paymentMethod) => {
      switch (paymentMethod.type) {
        case PAYMENT_METHOD_TYPES.BANK_ACCOUNT:
          PaymentMethodsOptions["Bank"] = PaymentMethodsOptions["Bank"] || {
            label: "Bank",
            options: [],
          };
          PaymentMethodsOptions["Bank"].options.push({
            label: `${paymentMethod.bankName} : ${paymentMethod.accountNumber}`,
            value: paymentMethod,
          });
          break;
        case PAYMENT_METHOD_TYPES.PHONE_NUMBER:
          PaymentMethodsOptions["Phonenumber"] = PaymentMethodsOptions[
            "Phonenumber"
          ] || { label: "Phonenumber", options: [] };
          PaymentMethodsOptions["Phonenumber"].options.push({
            label: `${paymentMethod.phonenumberLabel} : ${paymentMethod.phonenumber}`,
            value: paymentMethod,
          });
          break;
        case PAYMENT_METHOD_TYPES.WALLET_ADDRESS:
          PaymentMethodsOptions["Wallet Address"] = PaymentMethodsOptions[
            "Wallet Address"
          ] || { label: "Wallet Address", options: [] };
          PaymentMethodsOptions["Wallet Address"].options.push({
            label: `${paymentMethod.walletAddressLabel} : ${paymentMethod.walletAddress}`,
            value: paymentMethod,
          });
          break;
      }
    });

    const popStatePaymentMethods = [];
    Object.keys(PaymentMethodsOptions).forEach((key) => {
      const paymentMethodOption = PaymentMethodsOptions[key];
      popStatePaymentMethods.push(paymentMethodOption);
    });

    // Get proof of payments
    (async () => {
      const domain = api.getApiDomain();
      updatePopState({ key: "loadingPops", value: true });
      //const order = request.order;
      const result = await api.getOrderVerifiedProofOfPayments({
        token: authTokens.IdToken,
        order,
      });
      const proofOfPayments = result.data.data.map((datum) => {
        const pop = datum.attributes;
        const fileUrl = `${domain}/orders/${order.id}/requests/${pop.request.id}/proofofpayments/${pop.id}/files/${pop.file}?token=${authTokens.IdToken}`;
        pop.fileUrl = fileUrl;
        return pop;
      });
      const buyerProofOfPayments = proofOfPayments.filter(
        (pop) => pop.popType == "BUYER_POP"
      );
      const sellerProofOfPayments = proofOfPayments.filter(
        (pop) => pop.popType == "SELLER_POP"
      );

      if (userIsBuyer()) {
        updatePopStates([
          { key: "paymentMethods", value: popStatePaymentMethods },
          { key: "loadingPops", value: false },
          { key: "myPops", value: buyerProofOfPayments },
          { key: "otherPops", value: sellerProofOfPayments },
        ]);
      } else {
        updatePopStates([
          { key: "paymentMethods", value: popStatePaymentMethods },
          { key: "loadingPops", value: false },
          { key: "myPops", value: sellerProofOfPayments },
          { key: "otherPops", value: buyerProofOfPayments },
        ]);
      }
    })();

    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => URL.revokeObjectURL(fileToUpload.preview);
  }, []);

  const updatePopState = ({ key, value }) => {
    const newPopState = { ...popState };
    newPopState[key] = value;
    setPopState(newPopState);
  };

  const updatePopStates = (states) => {
    const newPopState = { ...popState };
    states.forEach(({ key, value }) => {
      newPopState[key] = value;
    });
    setPopState(newPopState);
  };

  const formatAmounts = (amount) => {
    const locale = Intl.NumberFormat().resolvedOptions().locale;
    let formatedAmount = parseFloat(amount);
    formatedAmount = `${formatedAmount.toLocaleString(locale)}`;
    return formatedAmount;
  };

  const viewPaymentMethod = (paymentMethod) => {
    switch (paymentMethod.type) {
      case PAYMENT_METHOD_TYPES.BANK_ACCOUNT:
        return `${paymentMethod.bankName} : ${paymentMethod.accountNumber}`;
      case PAYMENT_METHOD_TYPES.PHONE_NUMBER:
        return `${paymentMethod.phonenumberLabel} : ${paymentMethod.phonenumber}`;
      case PAYMENT_METHOD_TYPES.WALLET_ADDRESS:
        return `${paymentMethod.walletAddressLabel} : ${paymentMethod.walletAddress}`;
    }
  };

  const REQUEST_FORM_FIELDS = {
    receiptAmount: "receiptAmount",
  };
  const setMaxAmount = () => {
    const amount = userIsSeller()
      ? request.desiredPurchaseAmountConverted
      : request.desiredPurchaseAmount;

    setValue(REQUEST_FORM_FIELDS.receiptAmount, amount);
  };

  return (
    <div id="order_pops_component">
      <div className="my_documents">
        <div className="header">
          <div className="labe">My Documents</div>
    
        </div>

        {popState.loadingPops && (
          <div className="pop_list_loader_container">
            Loading documents...
            <Loader2 className="pop_list_loader" />
          </div>
        )}

        {!popState.loadingPops && (
          <div className="pop_list">
            {popState.myPops.length == 0 && (
              <div className="no_pop">No document yet..</div>
            )}

            {popState.myPops.map((pop) => (
              <div className="pop" key={pop.id}>
                <div className="image_preview_container">
                  <img src={pop.fileUrl} alt="" />
                </div>
                <div className="details_container">
                  <div className="pop_line_1">
                    <div className="pop_amount">
                      {formatAmounts(pop.amount)} {pop.currency}
                    </div>
                    {pop.popState == "PENDING" && (
                      <div className="pop_status pending">pending</div>
                    )}
                    {pop.popState == "VERIFIED" && (
                      <div className="pop_status verified">verified</div>
                    )}
                    {pop.popState == "REJECTED" && (
                      <div className="pop_status rejected">rejected</div>
                    )}
                  </div>
                  <div className="pop_line_2">
                    <div className="pop_payment_method_label">Paid to</div>
                    <div className="pop_payment_method">
                      {viewPaymentMethod(pop.request.paymentMethods[0])}
                    </div>
                  </div>
                </div>
                <div className="more_container">
                  <svg
                    className="detailsIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-ellipsis-vertical more_action"
                  >
                    <circle cx="12" cy="12" r="1" />
                    <circle cx="12" cy="5" r="1" />
                    <circle cx="12" cy="19" r="1" />
                  </svg>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="counter_party_documents">
        <div className="header">
          {userIsBuyer() && <> Seller's Documents</>}
          {userIsSeller() && <>Buyer's Documents</>}
        </div>
        {popState.loadingPops && (
          <div className="pop_list_loader_container">
            Loading documents...
            <Loader2 className="pop_list_loader" />
          </div>
        )}

        {!popState.loadingPops && (
          <div className="pop_list">
            {popState.otherPops.length == 0 && (
              <div className="no_pop">No document yet..</div>
            )}
            {popState.otherPops.map((pop) => (
              <div className="pop_container" key={pop.id}>
                <div className="pop">
                  <div className="image_preview_container">
                    <img src={pop.fileUrl} alt="" />
                  </div>
                  <div className="details_container">
                    <div className="pop_line_1">
                      <div className="pop_amount">
                        {formatAmounts(pop.amount)} {pop.currency}
                      </div>
                      {pop.popState == "PENDING" && (
                        <div className="pop_status pending">pending</div>
                      )}
                      {pop.popState == "VERIFIED" && (
                        <div className="pop_status verified">verified</div>
                      )}
                      {pop.popState == "REJECTED" && (
                        <div className="pop_status rejected">rejected</div>
                      )}
                    </div>
                    <div className="pop_line_2">
                      <div className="pop_payment_method_label">Paid to</div>
                      <div className="pop_payment_method">
                        {viewPaymentMethod(order.paymentMethods[0])}
                      </div>
                    </div>
                  </div>
                  <div className="more_container">
                    <svg
                      className="detailsIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="lucide lucide-ellipsis-vertical more_action"
                    >
                      <circle cx="12" cy="12" r="1" />
                      <circle cx="12" cy="5" r="1" />
                      <circle cx="12" cy="19" r="1" />
                    </svg>
                  </div>
                </div>
                {popState.updatingPops.includes(pop.id) && (
                  <div className="pop_actions_loader_container">
                    <Loader2 className="pop_actions_loader" />
                  </div>
                )}

                {/* {!popState.updatingPops.includes(pop.id) && pop.popState == "PENDING" && (
									<div className="pop_actions">
										<div
											className="reject"
											onClick={() => {
												rejectPop(pop);
											}}>
											Reject
										</div>
										<div
											className="verify"
											onClick={() => {
												verifyPop(pop);
											}}>
											Verify
										</div>
									</div>
								)} */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default OrderPops;
