import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  REQUESTS_ACTIONS,
  REQUEST_STATES,
  useRequests,
  useRequestsDispatch,
} from "../../../providers/RequestsProvider";
import "./allRequests.scss";
import api from "../../../api";
import { useAuth } from "../../../providers/AuthProvider";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  useTransactionDetailsDispatch,
  useTransactionDetails,
} from "../../transactionDetails/providers/transactionDetailsProvider";

const AllRequests = () => {
  const { isLoggedIn, authTokens } = useAuth();
  const {
    allRequests,
    isFetchingAllRequests,
    fetchAllRequests,
    isAcceptingRequest,
    acceptRequest,
  } = useRequests();
  const requestsDispatch = useRequestsDispatch();
  const { order } = useTransactionDetails();
  const [isRejectingRequest, setIsRejectingRequest] = useState(false); 
  const acceptRequestDialogRef = useRef(null);
  const rejectRequestDialogRef = useRef(null);
  const transactionDetailsDispatch = useTransactionDetailsDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        await fetchAllRequests();
      }
    })();
  }, [isLoggedIn]);

  const startAcceptRequest = () => {
    acceptRequestDialogRef.current.showModal();
  };

  const confirmAcceptRequest = async ({ request }) => {
    const token = authTokens.IdToken;
    await acceptRequest({ request, token });
    acceptRequestDialogRef.current.close();
    navigate(`/transactiondetails/request/${request.id}`);
  };

  const cancelAcceptRequest = () => {
    acceptRequestDialogRef.current.close();
  };

  const viewRequest = ({ request }) => {
    transactionDetailsDispatch({
      type: "updateState",
      key: "request",
      value: request,
    });
    navigate(`/transactiondetails/request/${request.id}`);
  };

  const rejectRequest = useMutation({
    mutationFn: ({ request, token }) => api.rejectRequest({ request, token }),
    onError: () => {},
    onSuccess: async (data, variables, context) => {
      await fetchAllRequests();
      const orderId = order.id;
      navigate(`/transactiondetails/${orderId}`);
    },
  });

  const startRejectRequest = () => {
    rejectRequestDialogRef.current.showModal();
  };

  const confirmRejectRequest = ({ request }) => {
    const token = authTokens.IdToken;
    // Show loader when the user clicks "Yes"
    setIsRejectingRequest(true);
    rejectRequest.mutate(
      { request, token },
      {
        onSuccess: async () => {
          // Hide loader after success
          setIsRejectingRequest(false);
          
          rejectRequestDialogRef.current.close();
          await fetchAllRequests();
        },
        onError: (error) => {
          console.error("Error rejecting request:", error);
          setIsRejectingRequest(false);
        },
      }
    );
  };

  const cancelRejectRequest = () => {
    rejectRequestDialogRef.current.close();
  };

  return (
    <div id="all_requests_component">
      {isFetchingAllRequests && (
        <div className="all_requests_loader_container">
          Loading requests...
          <Loader2 className="all_requests_loader" />
        </div>
      )}

      {!isFetchingAllRequests && (
        <table className="all_requests_table transactions_table">
          <thead>
            <tr>
              <th>
                Pair <span className="help_text">( Buy - Sell )</span>
              </th>
              <th>Price</th>
              <th>Amount</th>
              <th>User</th>
              <th>Date</th>
              <th>Actions</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {allRequests
              .filter(
                (request) =>
                  (request.order.id === order.id &&
                  request.requestState === "ACCEPTED" )|| (request.order.id === order.id && request.requestState === "CREATED")
              ) //GET ACCEPTED REQUESTS
              .map((request) => (
                <tr key={request.id}>
                  <td onClick={() => viewRequest({ request })}>
                    {request.order.sellCurrency} - {request.order.buyCurrency}
                  </td>
                  <td onClick={() => viewRequest({ request })}>
                    <p>
                      1 {request.order.sellCurrency} /{" "}
                      {request.processedOrder.price}
                    </p>
                  </td>
                  <td onClick={() => viewRequest({ request })}>
                    <p>
                      {request.order.buyCurrency}{" "}
                      {request.desiredPurchaseAmountFormatted}
                    </p>
                    <p>
                      {request.order.sellCurrency}{" "}
                      {request.desiredPurchaseAmountConvertedFormatted}
                    </p>
                  </td>
                  <td onClick={() => viewRequest({ request })}>
                    <p>{request.createdByName}</p>
                  </td>
                  <td onClick={() => viewRequest({ request })}>
                    {request.createdAtFormatted}
                  </td>
                  <td>
                    {request.requestState === REQUEST_STATES.CREATED && (
                      <div className="request_actions">
                        <div
                          className="action accept"
                          onClick={startAcceptRequest}
                        >
                          Accept
                        </div>
                        <dialog
                          className="accept_request_dialog_container"
                          ref={acceptRequestDialogRef}
                        >
                          <div className="accept_request_dialog_heading">
                            Are you sure you want to accept this request?
                          </div>
                          <div className="accept_request_dialog_controls">
                            {isAcceptingRequest && (
                              <div className="all_requests_loader_container">
                                Accepting request ...
                                <Loader2 className="all_requests_loader" />
                              </div>
                            )}

                            {!isAcceptingRequest && (
                              <>
                                <div
                                  onClick={() =>
                                    confirmAcceptRequest({ request })
                                  }
                                  className="accept_request_dialog_control accept_request_dialog_confirm"
                                >
                                  Yes
                                </div>
                                <div
                                  onClick={cancelAcceptRequest}
                                  className="accept_request_dialog_control accept_request_dialog_cancel"
                                >
                                  No
                                </div>
                              </>
                            )}
                          </div>
                        </dialog>

                        <div
                          className="action reject"
                          onClick={startRejectRequest}
                        >
                          Reject
                        </div>

                        <dialog
                          ref={rejectRequestDialogRef}
                          className="accept_request_dialog_container"
                        >
                          <div className="accept_request_dialog_heading">
                            Are you sure you want to reject this request?
                          </div>
                          <div className="accept_request_dialog_controls">
                            {isRejectingRequest && (
                              <div className="all_requests_loader_container">
                                Rejecting request ...
                                <Loader2 className="all_requests_loader" />
                              </div>
                            )}

                            {!isRejectingRequest && (
                              <>
                                <div
                                  onClick={() =>
                                    confirmRejectRequest({ request })
                                  }
                                  className="accept_request_dialog_control accept_request_dialog_confirm"
                                >
                                  Yes
                                </div>
                                <div
                                  onClick={cancelRejectRequest}
                                  className="accept_request_dialog_control accept_request_dialog_cancel"
                                >
                                  No
                                </div>
                              </>
                            )}
                          </div>
                        </dialog>
                      </div>
                    )}
                  </td>
                  <td>
                    <svg
                      className="detailsIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="1" />
                      <circle cx="12" cy="5" r="1" />
                      <circle cx="12" cy="19" r="1" />
                    </svg>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AllRequests;
