export const currencies = [
	{
		code: "USD",
		symbol: "$",
		name: "United States Dollar",
		logo: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg",
		isCrypto: false,
	},
	{ code: "ETH", symbol: "Ξ", name: "Ethereum", logo: "https://upload.wikimedia.org/wikipedia/commons/7/70/Ethereum_logo.svg", isCrypto: true },
	{ code: "BTC", symbol: "฿", name: "Bitcoin", logo: "https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg", isCrypto: true },
	{
		code: "NGN",
		symbol: "₦",
		name: "Nigerian Naira",
		logo: "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
		isCrypto: false,
	},
	{ code: "USDT", symbol: "₮", name: "Tether", logo: "/logos/tether.svg", isCrypto: true },
	{ code: "USDC", symbol: "USDC", name: "USD Coin", logo: "/logos/usdc.svg", isCrypto: true },
	{ code: "CUSD", symbol: "CUSD", name: "Celo Dollar", logo: "/logos/cusd.svg", isCrypto: true },
	{ code: "TRX", symbol: "TRX", name: "TRON", logo: "/logos/trx.svg", isCrypto: true },
	{ code: "CELO", symbol: "CELO", name: "Celo", isCrypto: true },
	{
		code: "UGX",
		symbol: "ush",
		name: "Ugandan Shilling",
		logo: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg",
		isCrypto: false,
	},
	{
		code: "KES",
		symbol: "KSh",
		name: "Kenyan Shilling",
		logo: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
		isCrypto: false,
	},
	{ code: "ALL", symbol: "Lek", name: "Albanian Lek", isCrypto: false },
	{ code: "AFN", symbol: "؋", name: "Afghan Afghani", isCrypto: false },
	{ code: "ARS", symbol: "$", name: "Argentine Peso", isCrypto: false },
	{ code: "AWG", symbol: "ƒ", name: "Aruban Florin", isCrypto: false },
	{ code: "AUD", symbol: "$", name: "Australian Dollar", isCrypto: false },
	{ code: "AZN", symbol: "₼", name: "Azerbaijani Manat", isCrypto: false },
	{ code: "BSD", symbol: "$", name: "Bahamian Dollar", isCrypto: false },
	{ code: "BYN", symbol: "Br", name: "Belarusian Ruble", isCrypto: false },
	{ code: "BZD", symbol: "BZ$", name: "Belize Dollar", isCrypto: false },
	{ code: "BMD", symbol: "$", name: "Bermudian Dollar", isCrypto: false },
	{ code: "BOB", symbol: "$b", name: "Bolivian Boliviano", isCrypto: false },
	{ code: "BAM", symbol: "KM", name: "Bosnia and Herzegovina Convertible Mark", isCrypto: false },
	{ code: "BWP", symbol: "P", name: "Botswana Pula", isCrypto: false },
	{ code: "BGN", symbol: "лв", name: "Bulgarian Lev", isCrypto: false },
	{ code: "BRL", symbol: "R$", name: "Brazilian Real", isCrypto: false },
	{ code: "BND", symbol: "$", name: "Brunei Dollar", isCrypto: false },
	{ code: "KHR", symbol: "៛", name: "Cambodian Riel", isCrypto: false },
	{ code: "CAD", symbol: "$", name: "Canadian Dollar", isCrypto: false },
	{ code: "KYD", symbol: "$", name: "Cayman Islands Dollar", isCrypto: false },
	{ code: "CLP", symbol: "$", name: "Chilean Peso", isCrypto: false },
	{ code: "CNY", symbol: "¥", name: "Chinese Yuan", isCrypto: false },
	{ code: "COP", symbol: "$", name: "Colombian Peso", isCrypto: false },
	{ code: "CRC", symbol: "₡", name: "Costa Rican Colón", isCrypto: false },
	{ code: "HRK", symbol: "kn", name: "Croatian Kuna", isCrypto: false },
	{ code: "CUP", symbol: "₱", name: "Cuban Peso", isCrypto: false },
	{ code: "CZK", symbol: "Kč", name: "Czech Koruna", isCrypto: false },
	{ code: "DKK", symbol: "kr", name: "Danish Krone", isCrypto: false },
	{ code: "DOP", symbol: "RD$", name: "Dominican Peso", isCrypto: false },
	{ code: "XCD", symbol: "$", name: "East Caribbean Dollar", isCrypto: false },
	{ code: "EGP", symbol: "£", name: "Egyptian Pound", isCrypto: false },
	{ code: "SVC", symbol: "$", name: "Salvadoran Colón", isCrypto: false },
	{ code: "EUR", symbol: "€", name: "Euro", isCrypto: false },
	{ code: "FKP", symbol: "£", name: "Falkland Islands Pound", isCrypto: false },
	{ code: "FJD", symbol: "$", name: "Fijian Dollar", isCrypto: false },
	{ code: "GHS", symbol: "¢", name: "Ghanaian Cedi", isCrypto: false, logo: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg" },
	{ code: "GIP", symbol: "£", name: "Gibraltar Pound", isCrypto: false },
	{ code: "GTQ", symbol: "Q", name: "Guatemalan Quetzal", isCrypto: false },
	{ code: "GGP", symbol: "£", name: "Guernsey Pound", isCrypto: false },
	{ code: "GYD", symbol: "$", name: "Guyanese Dollar", isCrypto: false },
	{ code: "HNL", symbol: "L", name: "Honduran Lempira", isCrypto: false },
	{ code: "HKD", symbol: "$", name: "Hong Kong Dollar", isCrypto: false },
	{ code: "HUF", symbol: "Ft", name: "Hungarian Forint", isCrypto: false },
	{ code: "ISK", symbol: "kr", name: "Icelandic Króna", isCrypto: false },
	{ code: "INR", symbol: "₹", name: "Indian Rupee", isCrypto: false },
	{ code: "IDR", symbol: "Rp", name: "Indonesian Rupiah", isCrypto: false },
	{ code: "IRR", symbol: "﷼", name: "Iranian Rial", isCrypto: false },
	{ code: "IMP", symbol: "£", name: "Isle of Man Pound", isCrypto: false },
	{ code: "ILS", symbol: "₪", name: "Israeli New Shekel", isCrypto: false },
	{ code: "JMD", symbol: "J$", name: "Jamaican Dollar", isCrypto: false },
	{ code: "JPY", symbol: "¥", name: "Japanese Yen", isCrypto: false },
	{ code: "JEP", symbol: "£", name: "Jersey Pound", isCrypto: false },
	{ code: "KZT", symbol: "лв", name: "Kazakhstani Tenge", isCrypto: false },
	{ code: "KPW", symbol: "₩", name: "North Korean Won", isCrypto: false },
	{ code: "KRW", symbol: "₩", name: "South Korean Won", isCrypto: false },
	{ code: "KGS", symbol: "лв", name: "Kyrgyzstani Som", isCrypto: false },
	{ code: "LAK", symbol: "₭", name: "Lao Kip", isCrypto: false },
	{ code: "LBP", symbol: "£", name: "Lebanese Pound", isCrypto: false },
	{ code: "LRD", symbol: "$", name: "Liberian Dollar", isCrypto: false },
	{ code: "MKD", symbol: "ден", name: "Macedonian Denar", isCrypto: false },
	{ code: "MYR", symbol: "RM", name: "Malaysian Ringgit", isCrypto: false },
	{ code: "MUR", symbol: "₨", name: "Mauritian Rupee", isCrypto: false },
	{ code: "MXN", symbol: "$", name: "Mexican Peso", isCrypto: false },
	{ code: "MNT", symbol: "₮", name: "Mongolian Tögrög", isCrypto: false },
	{ code: "MZN", symbol: "MT", name: "Mozambican Metical", isCrypto: false },
	{ code: "NAD", symbol: "$", name: "Namibian Dollar", isCrypto: false },
	{ code: "NPR", symbol: "₨", name: "Nepalese Rupee", isCrypto: false },
	{ code: "ANG", symbol: "ƒ", name: "Netherlands Antillean Guilder", isCrypto: false },
	{ code: "NZD", symbol: "$", name: "New Zealand Dollar", isCrypto: false },
	{ code: "NIO", symbol: "C$", name: "Nicaraguan Córdoba", isCrypto: false },
	{ code: "NOK", symbol: "kr", name: "Norwegian Krone", isCrypto: false },
	{ code: "OMR", symbol: "﷼", name: "Omani Rial", isCrypto: false },
	{ code: "PKR", symbol: "₨", name: "Pakistani Rupee", isCrypto: false },
	{ code: "PAB", symbol: "B/.", name: "Panamanian Balboa", isCrypto: false },
	{ code: "PYG", symbol: "Gs", name: "Paraguayan Guarani", isCrypto: false },
	{ code: "PEN", symbol: "S/.", name: "Peruvian Sol", isCrypto: false },
	{ code: "PHP", symbol: "₱", name: "Philippine Peso", isCrypto: false },
	{ code: "PLN", symbol: "zł", name: "Polish Zloty", isCrypto: false },
	{ code: "QAR", symbol: "﷼", name: "Qatari Rial", isCrypto: false },
	{ code: "RON", symbol: "lei", name: "Romanian Leu", isCrypto: false },
	{ code: "RUB", symbol: "₽", name: "Russian Ruble", isCrypto: false },
	{ code: "SHP", symbol: "£", name: "Saint Helena Pound", isCrypto: false },
	{ code: "SAR", symbol: "﷼", name: "Saudi Riyal", isCrypto: false },
	{ code: "RSD", symbol: "Дин.", name: "Serbian Dinar", isCrypto: false },
	{ code: "SCR", symbol: "₨", name: "Seychellois Rupee", isCrypto: false },
	{ code: "SGD", symbol: "$", name: "Singapore Dollar", isCrypto: false },
	{ code: "SBD", symbol: "$", name: "Solomon Islands Dollar", isCrypto: false },
	{ code: "SOS", symbol: "S", name: "Somali Shilling", isCrypto: false },
	{ code: "ZAR", symbol: "R", name: "South African Rand", isCrypto: false },
	{ code: "LKR", symbol: "₨", name: "Sri Lankan Rupee", isCrypto: false },
	{ code: "SEK", symbol: "kr", name: "Swedish Krona", isCrypto: false },
	{ code: "CHF", symbol: "CHF", name: "Swiss Franc", isCrypto: false },
	{ code: "SRD", symbol: "$", name: "Surinamese Dollar", isCrypto: false },
	{ code: "TWD", symbol: "NT$", name: "New Taiwan Dollar", isCrypto: false },
	{ code: "THB", symbol: "฿", name: "Thai Baht", isCrypto: false },
	{
		code: "TZS",
		symbol: "TSh",
		name: "Tanzanian Shilling",
		logo: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tanzania.svg",
		isCrypto: false,
	},
	{ code: "TOP", symbol: "T$", name: "Tongan Paʻanga", isCrypto: false },
	{ code: "TTD", symbol: "$", name: "Trinidad and Tobago Dollar", isCrypto: false },
	{ code: "TND", symbol: "د.ت", name: "Tunisian Dinar", isCrypto: false },
	{ code: "TRY", symbol: "₺", name: "Turkish Lira", isCrypto: false },
	{ code: "UAH", symbol: "₴", name: "Ukrainian Hryvnia", isCrypto: false },
	{
		code: "AED",
		symbol: "د.إ",
		name: "United Arab Emirates Dirham",
		isCrypto: false,
		logo: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
	},
	{
		code: "GBP",
		symbol: "£",
		name: "British Pound Sterling",
		isCrypto: false,
		logo: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg",
	},
	{ code: "VND", symbol: "₫", name: "Vietnamese Dong", isCrypto: false },
	{ code: "XOF", symbol: "CFA", name: "West African CFA Franc", isCrypto: false },
	{ code: "XAF", symbol: "CFA", name: "Central African CFA Franc", isCrypto: false },
	{ code: "ZMW", symbol: "ZK", name: "Zambian Kwacha", isCrypto: false, logo: "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg" },
	{ code: "ZWL", symbol: "Z$ ", name: "Zimbabwean Dollar", isCrypto: false },
	{ code: "ETB", symbol: "Br", name: "Ethiopian Birr", isCrypto: false, logo: "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg" },
];
