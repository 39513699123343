import { useState } from "react";
import cn from "classnames";
import _ from "lodash";
import "./myactivity.scss";
import MyOrders from "./myOrders/myOrders";
import Transactions from "./transactions/transactions";
import { Loader2, RefreshCw } from "lucide-react";
import { REQUESTS_ACTIONS, REQUEST_STATES, useRequests, useRequestsDispatch } from "../../providers/RequestsProvider"
import { useMyOrders, useMyOrdersDispatch, MY_ORDERS_ACTIONS} from "./myOrders/providers/myOrdersProvider"

const MyActivity = () => {
	const MY_ACTIVITY_TABS = {
		MY_ORDERS: "MY_ORDERS",
		TRANSACTIONS: "TRANSACTIONS",
	};
	const emptyMyActivityState = {
		activeTab: MY_ACTIVITY_TABS.MY_ORDERS,
	};
	const requestsDispatch = useRequestsDispatch();
	const ordersDispatch = useMyOrdersDispatch();
	const [myActivityState, setMyActivityState] = useState(emptyMyActivityState);
	const { fetchMyOrders, isFetchingMyOrders, getMyOrders } = useMyOrders();
	const { getReceivedRequests, getAllRequests, getCompleteRequests, isFetchingReceivedRequests,  isFetchingCompleteRequests, isFetchingAllRequests } = useRequests();
	const switchToTab = (tab) => {
		const newMyActivityState = { ...myActivityState };
		newMyActivityState.activeTab = tab;
		setMyActivityState(newMyActivityState);
	};

	const loadingOrders = () => {
		return isFetchingMyOrders  || isFetchingReceivedRequests || isFetchingCompleteRequests || isFetchingAllRequests;
	};
	const reloadPage = async () => {
		

		requestsDispatch({
			type: REQUESTS_ACTIONS.updateStates,
			states: {
				isFetchingReceivedRequests: true,
				isFetchingAllRequests: true,
				isFetchingCompleteRequests: true,
			},
		});

		ordersDispatch({ type: MY_ORDERS_ACTIONS.updateState, key:"isFetchingMyOrders", value: true });

		const ordersAndRequests = _.flattenDeep(
			await Promise.allSettled([getReceivedRequests.refetch(), getCompleteRequests.refetch(), getAllRequests.refetch(), getMyOrders.refetch()]))
		
			requestsDispatch({
				type: REQUESTS_ACTIONS.updateStates,
				states: {
					isFetchingReceivedRequests: false,
					isFetchingAllRequests: false,
					isFetchingCompleteRequests: false,
				},
			});

			ordersDispatch({ type: MY_ORDERS_ACTIONS.updateState, key:"isFetchingMyOrders", value: false});

	};

	return (
		<div id="myactivity_page">
			<div className="heading">
				<div className="title">My Activity</div>
				<RefreshCw onClick={reloadPage} className={cn("refresh_icon", { rotating: loadingOrders() })} />
			</div>
			<div className="body">
				<div className="myactivity_tab_list">
					<div
						onClick={() => {
							switchToTab(MY_ACTIVITY_TABS.MY_ORDERS);
						}}
						className={cn("tab", { active: myActivityState.activeTab == MY_ACTIVITY_TABS.MY_ORDERS })}>
						My Orders
					</div>
					<div
						onClick={() => {
							switchToTab(MY_ACTIVITY_TABS.TRANSACTIONS);
						}}
						className={cn("tab", { active: myActivityState.activeTab == MY_ACTIVITY_TABS.TRANSACTIONS })}>
						Transactions
					</div>
				</div>
				<div className="tab_content">
					{myActivityState.activeTab == MY_ACTIVITY_TABS.MY_ORDERS && <MyOrders />}
					{myActivityState.activeTab == MY_ACTIVITY_TABS.TRANSACTIONS && <Transactions />}
				</div>
			</div>
		</div>
	);
};
export default MyActivity;
