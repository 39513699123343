import "./otctrade.scss";
import { useRates } from "../../../../providers/Rates/RatesProvider";
import { useTransactDispatch } from "../../providers/TransactProvider";
import { ArrowRightLeft, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import Big from "big.js";
import _ from "lodash";
import ReactSelect from "react-select";

export const OTC_TRADE_VOLUME_TYPES = {
	SELL_RATE: "SELL_RATE",
	BUY_RATE: "BUY_RATE",
	PERCENTAGE: "PERCENTAGE",
};

const OTC_TRADE_VOLUME_TYPE_LABELS = {
	SELL_RATE: "Sell Rate",
	BUY_RATE: "Buy Rate",
	PERCENTAGE: "Percentage",
};

const ALLOWED_PERCENTAGE_PAIRS = ["USD - USDT", "USD - USDC", "USD - CUSD", "EURO - EURT"];

const Otctrade = () => {
	const [rateHelpTexts, setRateHelpTexts] = useState({
		buy: "",
		sell: "",
	});

	const [otcTradeState, setOtctradeState] = useState({
		initialRateLoaded: false,
		buy: {
			currency: "",
			amount: "",
			formattedAmount: "",
		},
		sell: {
			currency: "",
			amount: "",
			formattedAmount: "",
		},
		volume: {
			type: OTC_TRADE_VOLUME_TYPES.SELL_RATE,
			amount: "",
			typeAmount: 0,
			min: "",
		},
		window: {
			payment: {
				type: "MINUTES",
				amount: "60",
			},
			order: {
				type: "DAYS",
				amount: "3",
			},
		},
		buyRate: "",
		sellRate: "",
	});

	const { isLoadingRates, convert, rates, currencies, getRate } = useRates();
	const transactDispatch = useTransactDispatch();

	// Set initial rate after rates loaded
	useEffect(() => {
		if (!_.isEmpty(rates) && !otcTradeState.initialRateLoaded) {
			const newOtcTradeState = _.cloneDeep(otcTradeState);

			if (otcTradeState.sell.currency && otcTradeState.buy.currency) {
				const sellRate = getRate({
					rates,
					from: otcTradeState.sell.currency,
					to: otcTradeState.buy.currency,
				});
				newOtcTradeState.sellRate = Big(sellRate).toString();

				const buyRate = getRate({
					rates,
					from: otcTradeState.buy.currency,
					to: otcTradeState.sell.currency,
				});
				newOtcTradeState.buyRate = Big(buyRate).toString();

				newOtcTradeState.volume = {
					...newOtcTradeState.volume,
					type: OTC_TRADE_VOLUME_TYPES.SELL_RATE,
					typeAmount: Big(sellRate).toString(),
				};
			}

			setOtctradeState(newOtcTradeState);
			updateRateHelpTexts(newOtcTradeState);
		}
	}, [isLoadingRates]);

	const updateRateHelpTexts = (newOtcTradeState) => {
		const locale = Intl.NumberFormat().resolvedOptions().locale;
		const options = { minimumFractionDigits: 0, maximumFractionDigits: 10 };
		const newRateHelpTexts = {
			buy: `Buy Rate : 1 ${newOtcTradeState.buy.currency} = ${newOtcTradeState.buyRate.toLocaleString(locale, options)} ${newOtcTradeState.sell.currency}`,
			sell: `Sell Rate : 1 ${newOtcTradeState.sell.currency} = ${newOtcTradeState.sellRate.toLocaleString(locale, options)} ${newOtcTradeState.buy.currency}`,
		};

		setRateHelpTexts(newRateHelpTexts);
	};

	const formatAmount = (amount) => {
		amount = parseFloat(amount);
		const locale = Intl.NumberFormat().resolvedOptions().locale;
		const options = { minimumFractionDigits: 0, maximumFractionDigits: 10 };
		const result = `${amount.toLocaleString(locale, options)}`;
		return !_.isNaN(amount) ? result : "";
	};

	const buyCurrencyChanged = (evt) => {
		const newBuyCurrency = evt.target.value;
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.buy.currency = newBuyCurrency;

		const sellCurrency = otcTradeState.sell.currency;
		const buyCurrency = newOtcTradeState.buy.currency;

		if (sellCurrency && buyCurrency) {
			const sellRate = getRate({
				rates,
				from: sellCurrency,
				to: buyCurrency,
			});
			newOtcTradeState.sellRate = Big(sellRate).toString();

			const buyRate = getRate({
				rates,
				from: buyCurrency,
				to: sellCurrency,
			});
			newOtcTradeState.buyRate = Big(buyRate).toString();

			newOtcTradeState.volume = {
				...newOtcTradeState.volume,
				type: OTC_TRADE_VOLUME_TYPES.SELL_RATE,
				typeAmount: Big(sellRate).toString(),
			};

			//convert buy
			let newBuyAmount = "";
			if (!_.isEmpty(newOtcTradeState.sell.amount)) {
				newBuyAmount = convert({
					rates,
					from: sellCurrency,
					to: buyCurrency,
					amount: newOtcTradeState.sell.amount,
				});

				newOtcTradeState.buy.amount = newBuyAmount;
				newOtcTradeState.buy.formattedAmount = formatAmount(newBuyAmount);
			}
		}

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const buyAmountChanged = (evt) => {
		let amount = evt.target.value.split(",").join("").split("NaN").join("").trim("");
		try {
			amount = parseFloat(amount);
			if (_.isNaN(amount)) {
				amount = "";
			}
		} catch (err) {
			return;
		}

		const newBuyAmount = amount;
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.buy.amount = newBuyAmount;
		newOtcTradeState.buy.formattedAmount = formatAmount(newBuyAmount);

		let newSellAmount = "";
		if (newOtcTradeState.volume.type == OTC_TRADE_VOLUME_TYPES.SELL_RATE) {
			//convert to buy rate
			try {
				newSellAmount = Big(newOtcTradeState.buy.amount)
					.mul(Big(1).div(Big(newOtcTradeState.volume.typeAmount)))
					.toString();
			} catch (err) {
				console.log(err);
			}
		} else if (newOtcTradeState.volume.type == OTC_TRADE_VOLUME_TYPES.BUY_RATE) {
			//use rate directly
			try {
				newSellAmount = Big(newOtcTradeState.buy.amount).mul(Big(newOtcTradeState.volume.typeAmount)).toString();
			} catch (err) {
				console.log(err);
			}
		}

		newOtcTradeState.sell.amount = newSellAmount;
		newOtcTradeState.sell.formattedAmount = formatAmount(newSellAmount);

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const sellCurrencyChanged = (evt) => {
		const newSellCurrency = evt.target.value;
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.sell.currency = newSellCurrency;

		const sellCurrency = newOtcTradeState.sell.currency;
		const buyCurrency = otcTradeState.buy.currency;

		if (sellCurrency && buyCurrency) {
			const sellRate = getRate({
				rates,
				from: sellCurrency,
				to: buyCurrency,
			});
			newOtcTradeState.sellRate = Big(sellRate).toString();

			const buyRate = getRate({
				rates,
				from: buyCurrency,
				to: sellCurrency,
			});
			newOtcTradeState.buyRate = Big(buyRate).toString();

			newOtcTradeState.volume = {
				...newOtcTradeState.volume,
				type: OTC_TRADE_VOLUME_TYPES.SELL_RATE,
				typeAmount: Big(sellRate).toString(),
			};

			//convert buy using currency rate
			let newBuyAmount = "";
			if (!_.isEmpty(newOtcTradeState.sell.amount)) {
				newBuyAmount = convert({
					rates,
					from: sellCurrency,
					to: buyCurrency,
					amount: newOtcTradeState.sell.amount,
				});
				newOtcTradeState.buy.amount = newBuyAmount;
				newOtcTradeState.buy.formattedAmount = formatAmount(newBuyAmount);
			}
		}

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const sellAmountChanged = (evt) => {
		let amount = evt.target.value.split(",").join("").split("NaN").join("").trim("");
		try {
			amount = parseFloat(amount);
			if (_.isNaN(amount)) {
				amount = "";
			}
		} catch (err) {
			return;
		}

		const newSellAmount = amount;
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.sell.amount = newSellAmount;
		newOtcTradeState.sell.formattedAmount = formatAmount(newSellAmount);

		let newBuyAmount = "";
		if (newOtcTradeState.volume.type == OTC_TRADE_VOLUME_TYPES.SELL_RATE) {
			//use rate directly
			try {
				newBuyAmount = Big(newOtcTradeState.sell.amount).mul(Big(newOtcTradeState.volume.typeAmount)).toString();
			} catch (err) {}
		} else if (newOtcTradeState.volume.type == OTC_TRADE_VOLUME_TYPES.BUY_RATE) {
			//convert rate to sell rate
			try {
				newBuyAmount = Big(newOtcTradeState.sell.amount)
					.mul(Big(1).div(Big(newOtcTradeState.volume.typeAmount)))
					.toString();
			} catch (err) {}
		}

		newOtcTradeState.buy.amount = newBuyAmount;
		newOtcTradeState.buy.formattedAmount = formatAmount(newBuyAmount);

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const volumeTypeChanged = (evt) => {
		const newOtcTradeState = _.cloneDeep(otcTradeState);

		const newVolumeType = evt.target.value;
		let newTypeAmount;
		newOtcTradeState.volume.type = newVolumeType;

		const oldVolumeType = otcTradeState.volume.type;
		const oldVolumeTypeAmount = otcTradeState.volume.typeAmount;

		// If switching from buy rate to sell rate
		if (oldVolumeType == OTC_TRADE_VOLUME_TYPES.BUY_RATE && newVolumeType == OTC_TRADE_VOLUME_TYPES.SELL_RATE) {
			const buyRate = oldVolumeTypeAmount;
			const sellRate = Big(1).div(Big(buyRate)).toString();
			newTypeAmount = sellRate;
		}

		// If switching from sell rate to buy rate
		if (oldVolumeType == OTC_TRADE_VOLUME_TYPES.SELL_RATE && newVolumeType == OTC_TRADE_VOLUME_TYPES.BUY_RATE) {
			const sellRate = oldVolumeTypeAmount;
			const buyRate = Big(1).div(Big(sellRate)).toString();
			newTypeAmount = buyRate;
		}

		newOtcTradeState.volume.typeAmount = newTypeAmount;

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const volumeTypeAmountChanged = (evt) => {
		const newVolumeTypeAmount = evt.target.value;
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.volume.typeAmount = newVolumeTypeAmount;

		let sellRate = "0";
		let buyRate = "0";

		if (newOtcTradeState.volume.type == OTC_TRADE_VOLUME_TYPES.SELL_RATE && newVolumeTypeAmount > 0) {
			sellRate = Big(newOtcTradeState.volume.typeAmount).toString();
			buyRate = Big(1).div(Big(sellRate)).toString();
		} else if (newOtcTradeState.volume.type == OTC_TRADE_VOLUME_TYPES.BUY_RATE && newVolumeTypeAmount > 0) {
			buyRate = Big(newOtcTradeState.volume.typeAmount).toString();
			sellRate = Big(1).div(Big(buyRate)).toString();
		}

		newOtcTradeState.sellRate = sellRate;
		newOtcTradeState.buyRate = buyRate;

		let newBuyAmount = "";
		try {
			newBuyAmount = Big(newOtcTradeState.sell.amount).mul(Big(sellRate)).toString();
		} catch (err) {}
		newOtcTradeState.buy.amount = newBuyAmount;
		newOtcTradeState.buy.formattedAmount = formatAmount(newBuyAmount);

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const volumeMinChanged = (evt) => {
		const newVolumeMin = evt.target.value;
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.volume.min = newVolumeMin;

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const setVolumeMinToMax = () => {
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		const newVolumeMin = newOtcTradeState.sell.amount;
		newOtcTradeState.volume.min = newVolumeMin;

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const paymentWindowTypeChanged = (evt) => {
		const newPaymentWindowType = evt.target.value;
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.window.payment.type = newPaymentWindowType;

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const paymentWindowAmountChanged = (evt) => {
		const newPaymentAmountType = evt.target.value;
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.window.payment.amount = newPaymentAmountType;

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const orderWindowTypeChanged = (evt) => {
		const newOrderWindowType = evt.target.value;
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.window.order.type = newOrderWindowType;

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const orderWindowAmountChanged = (evt) => {
		const newOrderWindowAmount = evt.target.value;
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.window.order.amount = newOrderWindowAmount;

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const reactSelectStyle = {
		control: (baseStyles, state) => ({
			...baseStyles,
			fontSize: ".9rem",
			fontWeight: "600",
			padding: ".2rem",
			borderRadius: "8px",
			color: "#172c50",
			backgroundColor: "#e4efff",
			borderColor: "light-dark(rgb(118, 118, 118), rgb(133, 133, 133))",
		}),
	};

	const rateChanged = (evt) => {
		volumeTypeChanged({ target: { value: evt.value } });
	};

	const getRateValue = () => {
		return {
			value: otcTradeState.volume.type,
			label: OTC_TRADE_VOLUME_TYPE_LABELS[otcTradeState.volume.type],
		};
	};

	const getRateOptions = () => {
		const pair = `${otcTradeState.buy.currency} - ${otcTradeState.sell.currency}`;
		const reversePair = `${otcTradeState.sell.currency} - ${otcTradeState.buy.currency}`;
		const options = [
			{ value: OTC_TRADE_VOLUME_TYPES.SELL_RATE, label: OTC_TRADE_VOLUME_TYPE_LABELS.SELL_RATE },
			{ value: OTC_TRADE_VOLUME_TYPES.BUY_RATE, label: OTC_TRADE_VOLUME_TYPE_LABELS.BUY_RATE },
		];
		if (ALLOWED_PERCENTAGE_PAIRS.includes(pair) || ALLOWED_PERCENTAGE_PAIRS.includes(reversePair)) {
			options.push({ value: OTC_TRADE_VOLUME_TYPES.PERCENTAGE, label: OTC_TRADE_VOLUME_TYPE_LABELS.PERCENTAGE });
		}
		return options;
	};

	const flipCurrencies = () => {
		const newOtcTradeState = _.cloneDeep(otcTradeState);
		newOtcTradeState.buy = _.cloneDeep(otcTradeState.sell);
		newOtcTradeState.buyRate = `${otcTradeState.sellRate}`;

		newOtcTradeState.sell = _.cloneDeep(otcTradeState.buy);
		newOtcTradeState.sellRate = `${otcTradeState.buyRate}`;

		newOtcTradeState.volume = {
			...newOtcTradeState.volume,
			type: OTC_TRADE_VOLUME_TYPES.SELL_RATE,
			typeAmount: Big(newOtcTradeState.sellRate).toString(),
			min: `${otcTradeState.buy.amount}`,
		};

		setOtctradeState(newOtcTradeState);
		updateRateHelpTexts(newOtcTradeState);
	};

	const getBuyPlaceholder = () => {
		const placeholder = `Enter ${otcTradeState.buy.currency} Amount`;
		return placeholder;
	};

	const getSellPlaceholder = () => {
		const placeholder = `Enter ${otcTradeState.sell.currency} Amount`;
		return placeholder;
	};

	const getRateOptionLabel = (option) => {
		let helpText = "( does this work? )";
		switch (option.value) {
			case OTC_TRADE_VOLUME_TYPES.SELL_RATE:
				helpText = `1 ${otcTradeState.sell.currency} = xx ${otcTradeState.buy.currency}`;
				break;
			case OTC_TRADE_VOLUME_TYPES.BUY_RATE:
				helpText = `1 ${otcTradeState.buy.currency} = xx ${otcTradeState.sell.currency}`;
				break;
		}

		return (
			<div>
				{`${option.label}`}
				<br /> <span className="help_text">{helpText}</span>
			</div>
		);
	};

	const getBuyCurrencyValue = () => {
		if (_.isEmpty(otcTradeState.buy.currency)) return null;
		return { option: otcTradeState.buy.currency, label: otcTradeState.buy.currency };
	};

	const getSellCurrencyValue = () => {
		if (_.isEmpty(otcTradeState.sell.currency)) return null;
		return { option: otcTradeState.sell.currency, label: otcTradeState.sell.currency };
	};

	return (
		<div id="otctrade_component">
			{isLoadingRates && (
				<div className="rates_loader_container">
					Loading rates...
					<Loader2 className="rates_loader" />
				</div>
			)}

			{!isLoadingRates && (
				<>
					<div className="currency_inputs">
						<div className="currency_input_box buy_currency">
							<div className="currency_trade_label">
								Buy
								<span className="help_text">( What i want )</span>
							</div>

							<div className="currency_input currency">
								<ReactSelect
									placeholder={"Enter Currency"}
									onChange={(evt) => buyCurrencyChanged({ target: { value: evt.value } })}
									styles={reactSelectStyle}
									value={getBuyCurrencyValue()}
									options={currencies.map((c) => ({
										value: c.code,
										label: c.code,
									}))}></ReactSelect>
							</div>
							<div className="currency_input amount">
								<input placeholder={getBuyPlaceholder()} type="text" value={otcTradeState.buy.formattedAmount} onChange={buyAmountChanged} />
								<span className="currency_exchange_amount">{rateHelpTexts.buy}</span>
							</div>
						</div>
						<div className="currency_flipper_box">
							<ArrowRightLeft className="currency_flipper" onClick={flipCurrencies} />
						</div>
						<div className="currency_input_box sell_currency">
							<div className="currency_trade_label">
								Sell
								<span className="help_text">( What i have )</span>
							</div>
							<div className="currency_input currency">
								<ReactSelect
									placeholder={"Enter Currency"}
									onChange={(evt) => sellCurrencyChanged({ target: { value: evt.value } })}
									value={getSellCurrencyValue()}
									styles={reactSelectStyle}
									options={currencies.map((c) => ({
										value: c.code,
										label: c.code,
									}))}
								/>
							</div>
							<div className="currency_input amount sell_amount">
								<input placeholder={getSellPlaceholder()} type="text" value={otcTradeState.sell.formattedAmount} onChange={sellAmountChanged} />
								<span className="currency_exchange_amount">{rateHelpTexts.sell}</span>
							</div>
						</div>
					</div>
					<div className="volume_inputs">
						<div className="volume_type_input_box">
							<div className="volume_type_input volume_type">
								<ReactSelect
									value={getRateValue()}
									onChange={rateChanged}
									styles={reactSelectStyle}
									options={getRateOptions()}
									getOptionLabel={getRateOptionLabel}
								/>
							</div>
							<div className="volume_type_input volume_type_amount">
								<input className="hide_number_arrows" type="number" min="0" value={otcTradeState.volume.typeAmount} onChange={volumeTypeAmountChanged} />
							</div>
						</div>
						<div className="min_volume_input_box">
							<div className="min_volume_label">Min. Order Volume</div>
							<div className="min_volume_input">
								<input className="hide_number_arrows" type="number" min="0" value={otcTradeState.volume.min} onChange={volumeMinChanged} />
								<span onClick={setVolumeMinToMax}>MAX</span>
							</div>
						</div>
					</div>
					<div className="window_inputs">
						<div className="payment_window_input_box">
							<div className="payment_window_label">Payment window</div>
							<div className="payment_window_input payment_window_type">
								<ReactSelect
									value={{
										value: otcTradeState.window.payment.type,
										label: otcTradeState.window.payment.type,
									}}
									onChange={(evt) => paymentWindowTypeChanged({ target: { value: evt.value } })}
									styles={reactSelectStyle}
									options={[
										{ value: "MINUTES", label: "Minutes" },
										{ value: "HOURS", label: "Hours" },
										{ value: "DAYS", label: "Days" },
									]}
								/>
							</div>
							<div className="payment_window_input payment_window_value">
								<input className="hide_number_arrows" type="number" min="0" value={otcTradeState.window.payment.amount} onChange={paymentWindowAmountChanged} />
							</div>
						</div>
						<div className="order_window_input_box">
							<div className="order_window_label">Order window</div>
							<div className="order_window_input order_window_type">
								<ReactSelect
									value={{
										value: otcTradeState.window.order.type,
										label: otcTradeState.window.order.type,
									}}
									onChange={(evt) => orderWindowTypeChanged({ target: { value: evt.value } })}
									styles={reactSelectStyle}
									options={[
										{ value: "MINUTES", label: "Minutes" },
										{ value: "HOURS", label: "Hours" },
										{ value: "DAYS", label: "Days" },
									]}
								/>
							</div>
							<div className="order_window_input order_window_value">
								<input className="hide_number_arrows" type="number" min="0" value={otcTradeState.window.order.amount} onChange={orderWindowAmountChanged} />
							</div>
						</div>
					</div>
					<div className="summary">
						<div className="balance">
							<div className="balance_label">Total balance:</div>
							<div className="balance_amount">{0}</div>
						</div>
						<div
							onClick={() => {
								transactDispatch({
									type: "configurePaymentMethods",
									otcTradeState,
								});
							}}
							className="place_order">
							Place order
						</div>
					</div>
				</>
			)}
		</div>
	);
};
export default Otctrade;
