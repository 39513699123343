import Big from "big.js";
import moment from "moment/moment";
import { formatAmount } from "./formatAmount";
import _ from "lodash";
export const getProcessedOrder = ({ order }) => {
    let maxPurchaseAmount = Big(order.volumeTypeAmount).mul(Big(order.sellAmount)).toString();
    const amount = `${formatAmount(maxPurchaseAmount)} ${order.buyCurrency}`;

    const balanceAmount = Big(order.balance).toNumber();
    const balance = `${formatAmount(order.balance)} ${order.buyCurrency}`;

    const totalVolume = `${formatAmount(Big(order.balance).div(Big(order.volumeTypeAmount)).toString())}`;

    const date = moment(order.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a");

    const buyPrice = `1 ${order.buyCurrency} = ${formatAmount(Big(1).div(Big(order.volumeTypeAmount)).toString())} ${order.sellCurrency}`
    const sellPrice = `1 ${order.sellCurrency} = ${formatAmount(order.volumeTypeAmount)} ${order.buyCurrency}`

    let expiry = "---";
    if (!_.isEmpty(order.expiry)) {
        expiry = moment(order.expiry).format("MMM D, YYYY [at] h:mm A")
    }

    const procesedOrder = {
        id: order.id,
        pair: `${order.buyCurrency} - ${order.sellCurrency}`,
        price: `${formatAmount(order.volumeTypeAmount)} ${order.buyCurrency}`,
        buyPrice,
        sellPrice,
        amount,
        balance,
        balanceAmount,
        maxPurchaseAmount,
        username: `${order.createdByName}`,
        createdBy: `${order.createdBy}`,
        expiry,
        totalVolume,
        date,
        minOrderVolume: `${order.volumeMin}`,
        windowOrderAmount: `${order.windowOrderAmount}`,
        windowOrderType: `${order.windowOrderType}`,
        windowPaymentAmount: `${order.windowPaymentAmount}`,
        windowPaymentType: `${order.windowPaymentType}`,
        paymentMethods: order.paymentMethods,
        depositAmounts: order.depositAmounts,
        _rawTxn: order
    };

    switch (order.orderStatus) {
        case "PENDING":
            procesedOrder.status = "OPEN"
            break
        case "CANCELLED":
            procesedOrder.status = "CANCELLED"
            break
        case "FULFILLED":
            procesedOrder.status = "FULFILLED"
            break
        default:
            procesedOrder.status = `N/A :: ${order.orderStatus}`
            break;
    }

    return procesedOrder;
};