import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";

const Countdown = ({ to }) => {
	const [timeLeft, setTimeLeft] = useState("...");

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft(to));
		}, 500);
		return () => clearInterval(timer);
	}, [to]);

	const calculateTimeLeft = (date) => {
		const now = moment();
		const target = moment(date);
		const difference = target.diff(now);

		if (difference <= 0) {
			return `00h 00m 0s`;
		}

		const duration = moment.duration(difference);
		const hours = Math.floor(duration.asHours());
		const minutes = duration.minutes();
		const seconds = duration.seconds();

		return `${hours}h ${minutes}m ${seconds}s`;
	};

	const isValidDate = (date) => {
		return !_.isEmpty(date) && moment(date).isValid();
	};

	return (
		<div style={{ minWidth: "6rem", textAlign: "center" }}>
			{!isValidDate(to) && <div>--</div>}
			{isValidDate(to) && <div>{timeLeft}</div>}
		</div>
	);
};

export default Countdown;
